<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>充电客户>客户司机详情
 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <template v-for="(button, index) in pageButtons">
          <ykc-button
            :key="index"
            v-if="button.enabled"
            v-bind="button"
            @click="button.handleClick(button, $event)">
            {{ button.text }}
          </ykc-button>
        </template>
      </div>
    </template>
    <div class="fleet-match-content">
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header">
          <span>基础资料</span>
        </div>
        <div class="baseInfo">
          <div class="header">
            <div class="head">
              <img v-if="detail?.imageUrl" :src="detail.imageUrl" alt="" class="" />
              <img v-else src="@/assets/images/card-no-img.png" alt="暂无图片" class="" />
              <div>Id:{{ dealData(baseDetail.id) }}</div>
              <!-- <div class="vip"></div> -->
            </div>
          </div>
          <div class="content">
            <div class="top">
              <div>
                <span class="label">账号:</span>
                <span class="value">
                  {{ dealData(baseDetail.userAccount) }}
                </span>
              </div>
              <div>
                <span class="label">手机号:</span>
                <span class="value">
                  {{ dealData(baseDetail.userPhone) }}
                </span>
              </div>
              <div>
                <span class="label">注册时间:</span>
                <span class="value">
                  {{ dealData(baseDetail.createdTime) }}
                </span>
              </div>
              <div>
                <span class="label">姓名:</span>
                <span class="value">
                  {{ dealData(baseDetail.nickName) }}
                </span>
              </div>
              <div>
                <span class="label">城市:</span>
                <span class="value">
                  {{ dealData(districtName) }}
                </span>
              </div>
              <div>
                <span class="label">最近登录时间:</span>
                <span class="value">
                  {{ dealData(baseDetail.lastLoginTime) }}
                </span>
              </div>
              <div>
                <span class="label">实体卡卡号:</span>
                <span class="value">
                  {{ dealData(baseDetail.chargeCardNo) }}
                </span>
              </div>
              <div>
                <span class="label">注册渠道:</span>
                <span class="value">
                  {{ dealData(baseDetail.registerType) }}
                </span>
              </div>
              <div>
                <span class="label">最近充电时间:</span>
                <span class="value">
                  {{ dealData(baseDetail.lastChargeTime) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </YkcDetailPlus>
      <div
        style="
          display: flex;
          width: 100%;
          height: 800px;
          flex: 1;
          flex-direction: row;
          justify-content: space-between;
        ">
        <YkcDetailPlus class="box-card-prop-left" :labelWidth="150">
          <div slot="header" class="clearfix">
            <span>账号属性</span>
          </div>
          <div class="customerProperty">
            <div class="item-left" v-if="type != 'customerCustomer'">
              <span class="label">归属机构:</span>
              <span class="value">
                {{ dealData(baseDetail.belongToName) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">启充金额:</span>
              <span class="value">
                {{ '￥' + dealData(baseDetail.startLimitAmount) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">停充金额:</span>
              <span class="value">
                {{ '￥' + dealData(baseDetail.stopLimitAmount) }}
              </span>
            </div>
            <div class="item-left">
              <span class="label">一号多充:</span>
              <span class="value">
                {{ dealData(baseDetail.multipleChargeType) == 0 ? '关闭' : '开启' }}
              </span>
            </div>
          </div>
        </YkcDetailPlus>
        <YkcDetailPlus class="box-card-prop-right" :labelWidth="150">
          <div slot="header" class="clearfix">
            <span>绑定车辆</span>
          </div>
          <div class="more-car" @click="JumpToCarPage" v-if="type !== 'customerCustomer'">
            <span>更多车辆</span>
          </div>
          <scroll-layout :showFooter="false" style="margin-top: -20px; padding-bottom: 0px">
            <ykc-table
              ref="YkcTable"
              style="height: 300px"
              operateFixedType="right"
              :hasPageNation="false"
              :columns="tableColumns"
              :data="baseDetail?.carInfos || []"></ykc-table>
          </scroll-layout>
        </YkcDetailPlus>
      </div>
      <!-- <YkcDetailPlus class="box-card" :labelWidth="150" v-if="type == 'customerCustomer'">
        <div slot="header" class="clearfix">
          <span>会员信息</span>
          <span class="more-vip" @click="toVip">更多会员信息>></span>
        </div>
        <div class="vipInfo">
          <div class="item">
            <div class="title">
              累计积分
              <div
                class="tRight"
                @click="handleIntegralDetail"
                v-rbac="'customer:customer:detail:score'">
                明细
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg1"></div>
            <span class="value">{{ detail.points }}</span>
            <span
              class="link"
              @click="openAdjustIntegralDialog"
              v-rbac="'customer:customer:detail:changescore'">
              调整积分
            </span>
          </div>
          <div class="item">
            <div class="title">
              累计成长值
              <div
                class="tRight"
                @click="handleGrowthValueDetail"
                v-rbac="'customer:customer:detail:growscore'">
                明细
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg2"></div>
            <span class="value">{{ detail.growthValue }}</span>
            <span
              class="link"
              @click="openAdjustGrowthValueDialog"
              v-rbac="'customer:customer:detail:changegrowscore'">
              调整成长值
            </span>
          </div>
          <div class="item">
            <div class="title">
              会员等级
              <div
                class="tRight"
                @click="showDialog('level')"
                v-rbac="'customer:customer:detail:level'">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg3"></div>
            <span class="value">{{ detail.levelName }}</span>
            <span class="link"></span>
          </div>
        </div>
      </YkcDetailPlus> -->
      <YkcDetailPlus class="box-card" :labelWidth="150">
        <div slot="header" class="clearfix">
          <span>账户信息</span>
        </div>
        <div class="userInfo">
          <div class="item">
            <div class="title">
              {{ type == 'customerCustomer' ? '个人' : '企业' }}账户余额(元)
              <div class="tRight" @click="handleAccountMoneyDetail" v-if="code(getCode('balance'))">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg4"></div>
            <span class="value">{{ detail.balance }}</span>
          </div>
          <div class="item">
            <div class="title">
              可用优惠券(张)
              <div class="tRight" @click="showDialog('coupon')" v-if="code(getCode('coupon'))">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg5"></div>
            <span class="value">{{ detail.couponCount }}</span>
          </div>
          <div class="item">
            <div class="title">
              可用抵用卡(张)
              <div class="tRight" @click="showDialog('card')" v-if="code(getCode('card'))">
                查看
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="itembg bg6"></div>
            <span class="value">{{ detail.cardCount }}</span>
          </div>
        </div>
      </YkcDetailPlus>
    </div>
    <YkcOperationDialog
      :title="dialogTitle"
      :show.sync="showDetail"
      :before-close="onDialogClose"
      :before-cancel="beforeCancel"
      :showFooter="!['level', 'coupon', 'card'].includes(dialogType)">
      <div slot="content">
        <!-- 会员等级 -->
        <div class="levelInfo" v-if="dialogType === 'level'">
          <div class="card" :class="[`vip${detail.level}`]">
            <span class="title">{{ vipLevelInfo.levelName }}</span>
            <div class="text" v-if="vipLevelInfo.curLevelMaxGrowth">
              <span>{{ detail.growthValue }}</span>
              /{{ vipLevelInfo.curLevelMaxGrowth }} 成长值 距离升级还需{{
                Number(vipLevelInfo.curLevelMaxGrowth) - Number(detail.growthValue)
              }}成长值
            </div>
            <div class="text" v-else>
              <span>{{ detail.growthValue }}/成长值</span>
              已达到最高等级
            </div>
            <div class="progress">
              <div
                class="valueLine"
                :style="{
                  maxWidth: '100%',
                  width: `${
                    (Number(detail.growthValue) / Number(vipLevelInfo.curLevelMaxGrowth)) * 100
                  }%`,
                }"></div>
            </div>
          </div>
          <div class="quanyi">
            <div class="title">会员权益</div>
            <div class="_item">
              <img src="@/assets/images/item1.png" alt="" />
              <div>
                <div class="name">会员折扣</div>
                <div class="detail">
                  {{ vipLevelInfo.discountTypeDesc }}{{ vipLevelInfo.discountRatioDesc }}
                </div>
              </div>
            </div>
            <div class="_item">
              <img src="@/assets/images/item2.png" alt="" />
              <div>
                <div class="name">升级礼包</div>
                <div class="detail">{{ vipLevelInfo.giftPackageName }}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- 优惠券 -->
        <div class="coupon" v-if="dialogType === 'coupon'">
          <template v-if="couponList.length > 0">
            <div
              class="_item"
              style="padding: 10px 0"
              v-for="(item, index) in couponList"
              :key="index">
              <div class="left">
                <div class="money">
                  ￥
                  <span>{{ item.faceValue }}</span>
                </div>
                <div class="param">充电满</div>
                <div class="param" style="margin-top: 2px">{{ item.amountLimit }}元可用</div>
              </div>
              <div class="right">
                <div class="title">{{ item.cardCouponName }}</div>
                <div class="info">适用电站：全平台</div>
                <div class="info card-expire-time">
                  <div>有效期至：{{ item.endTime.split(' ')[0] }}</div>
                  <div v-if="item.willExpireFlag === true" class="expire-tag">即将到期</div>
                </div>
              </div>
            </div>
          </template>
          <div v-else class="ykc-table-empty">
            <img src="../../../../assets/images/no-data.png" alt="table-empty" />
            <span>暂无数据</span>
          </div>
        </div>
        <!-- 抵用卡 -->
        <div class="card" v-if="dialogType === 'card'">
          <template v-if="cardList.length > 0">
            <div
              class="_item"
              style="padding: 10px 0"
              v-for="(item, index) in cardList"
              :key="index">
              <div class="left"></div>
              <div class="right">
                <div class="title">{{ item.cardCouponName }}</div>
                <div class="info">剩余额度：{{ item.availableAmount }} 元</div>
                <div class="info">有效期至：{{ item.endTime.split(' ')[0] }}</div>
              </div>
            </div>
          </template>
          <div v-else class="ykc-table-empty">
            <img src="../../../../assets/images/no-data.png" alt="table-empty" />
            <span>暂无数据</span>
          </div>
        </div>
      </div>
    </YkcOperationDialog>
    <DrawerPS :show-cwd="showCwd" :data-form="ruleForm" @change-show-cwd="changeShowCwd"></DrawerPS>
    <ykc-drawer
      :title="drawerTitle"
      :show.sync="showDrawer"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <ImportData ref="addOrEdit" v-if="drawerType === 'import'"></ImportData>
        <DrawerCustomer
          v-if="drawerType === 'edit' && type == 'customerCustomer'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"></DrawerCustomer>
        <DrawerCharge
          v-if="drawerType === 'edit' && type == 'customerCharge'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"></DrawerCharge>
        <DrawerInternal
          v-if="drawerType === 'edit' && type == 'customerInternal'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"></DrawerInternal>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  // import openAdjustDialog from './dialog';
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import { code, dealData, getListNameById } from '@/utils';
  import { marketManage, treeUtils, customerUser } from '@/service/apis';

  import DrawerPS from './DrawerPs.vue';
  import DrawerCharge from './AddOrEdit.vue';
  import DrawerCustomer from '../../customerCustomer/AddOrEdit.vue';
  import DrawerInternal from '../../internalDriver/subsidiaryDriver/AddOrEdit.vue';

  export default {
    name: 'SellingPriceDetail',
    components: { DrawerPS, DrawerCustomer, DrawerCharge, DrawerInternal },
    data() {
      return {
        dialogType: '',
        districtName: '',
        stepsIndex: 0,
        maxStepIndex: 1,
        showCwd: false,
        showDrawer: false,
        showDetail: false,
        ruleForm: {},
        vipLevelInfo: {},
        cardList: [],
        districts: [],
        couponList: [],
        baseDiscountList: [],
        baseDetail: {
          id: '',
          userAccount: 'sc7',
          nickName: 'sc7',
          userPhone: '18522222223',
          provinceId: null,
          districtId: null,
          districtName: '',
          chargeCardNo: null,
          startLimitAmount: null,
          stopLimitAmount: null,
          multipleChargeType: null,
          userAvatar: null,
          registerType: null,
          userType: null,
          lastLoginTime: null,
          createdTime: null,
          belongToId: null,
          orgName: null,
          mainOrgId: null,
          status: '0',
          belongToName: 'sc1',
          mainOrgName: 'sc',
          balance: 3.0,
          level: null,
          levelName: null,
          points: null,
          availableCouponCount: null,
          availableCardCount: null,
          carInfos: null,
        },
        detail: {
          memberName: '',
          city: '',
          orgName: '',
          age: '',
          sex: '',
          memberAccount: '',
          memberNo: '',
          phone: '',
          lastChargeTime: '',
          lastLoginTime: '',
          registerTime: '',
          registerChannel: '',
          imageUrl: '',
          balance: '',
          couponIds: ['string'],
          couponCount: '',
          cardIds: ['string'],
          cardCount: '',
          level: '',
          points: '',
          growthValue: '',
          levelName: '',
          remark: '',
          groupList: [],
        },
        tableColumns: [
          { label: '车牌号', prop: 'plateNumber', minWidth: '60px' },
          { label: 'VIN', prop: 'vinCode', minWidth: '60px' },
          {
            label: '车辆产权',
            prop: 'propertyRight',
            minWidth: '100px',
            scopedSlots: {
              default: ({ row }) => {
                const title =
                  // eslint-disable-next-line no-nested-ternary
                  row.propertyRight === '2'
                    ? '车队车辆'
                    : row.propertyRight === '1'
                    ? '个人车辆'
                    : '-';
                return title;
              },
            },
          },
        ],
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                showTitle: false,
                showFooter: true,
                dialogType: 'feedback',
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              this.$refs.addOrEdit
                .submitForm()
                .then(() => {
                  this.stepsIndex = 0;
                  this.showDrawer = false;
                  this.isRequest = false;
                  this.getBaseDetail();
                })
                .finally(() => {
                  this.isRequest = false;
                });
            },
          },
        ],
      };
    },
    computed: {
      pageButtons() {
        return [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            enabled: () => true,
            handleClick: () => {
              this.$router.go(-1);
            },
          },
          {
            id: '2',
            text: '编辑',
            type: 'plain',
            enabled: () => code(this.getCode('edit')),
            handleClick: () => {
              this.handleEdit();
            },
          },
          {
            id: '3',
            text: '修改密码',
            type: 'plain',
            enabled: () => code(this.getCode('pwd')),
            handleClick: () => {
              this.ruleForm = {
                accountNo: this.baseDetail.userAccount,
                accountName: this.baseDetail.nickName,
                accountPwd: '',
                id: this.id,
              };
              this.showCwd = true;
            },
          },
        ];
      },
      dialogTitle() {
        if (this.dialogType === 'level') {
          return '会员等级详情';
        }
        if (this.dialogType === 'coupon') {
          return '可用优惠券';
        }
        if (this.dialogType === 'card') {
          return '可用抵用卡';
        }
        return '';
      },
      drawerTitle() {
        if (this.type === 'customerCustomer') {
          return 'C端客户编辑';
        }
        if (this.type === 'customerCharge') {
          return '客户司机编辑';
        }
        return '内部司机编辑';
      },
    },
    created() {
      const { id, type } = this.$route.query;
      this.id = id;
      this.type = type;
      console.log('this.$route', this.$route);
      this.getDetail();
      this.getBaseDetail();
      // this.getLevelInfo();
      this.getCardCouponInfo();
      this.getDistrict();
    },
    methods: {
      dealData,
      getListNameById,
      code,
      getCityNameByCityId() {
        let provinceName = '';
        let cityName = '';
        if (this.detail && this.detail.provinceId) {
          const provinceItem = this.districts.find(
            item => String(item.id) === String(this.detail.provinceId)
          );
          if (provinceItem) {
            provinceName = provinceItem.name;
          }
        }
        if (this.detail && this.detail.cityId) {
          const cityItem = this.districts.find(
            item => String(item.id) === String(this.detail.cityId)
          );
          if (cityItem) {
            cityName = cityItem.name;
          }
        }
        if (!provinceName && !cityName) return '————';
        return `${provinceName} ${cityName}`;
      },
      getDistrict() {
        treeUtils.getDistrict({ level: 3 }).then(({ districts }) => {
          const data = districts || [];
          const items = [];
          for (let i = 0; i < data.length; i++) {
            items.push(data[i]);
            if (Array.isArray(data[i].subNodes)) {
              data.push(...data[i].subNodes);
            }
          }
          console.log('districts', items);
          this.districts = items;
        });
      },
      changeShowCwd(val) {
        this.showCwd = val;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      jumpToEdit() {
        this.type = this.$route.query.type;
        let pathUrl = '';
        if (this.type === 'customerCharge') {
          pathUrl = '/customerCenter/customerCharge/customerDriver/customerDriverList';
        } else if (this.type === 'customerInternal') {
          pathUrl = '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverList';
        } else if (this.type === 'customerCustomer') {
          pathUrl = '/customerCenter/customerCustomer/customerCustomerList';
        }
        this.$router.push({
          path: pathUrl,
          query: {
            id: this.id,
            userAccount: this.baseDetail.userAccount,
          },
        });
      },
      showDialog(type) {
        this.dialogType = type;
        this.showDetail = true;
      },
      onDialogClose(...args) {
        console.log('onDialogClose', args);
      },
      beforeCancel(...args) {
        console.log('beforeCancel', args);
      },
      goUrl(url) {
        this.$router.push(url);
      },
      getBaseDetail() {
        customerUser
          .detail({ id: this.id })
          .then(data => {
            this.baseDetail = data;
            // this.baseDiscountList = [this.details.baseDiscount] || [];
            console.log('this.details---', this.baseDetail);
            this.getCity(data.districtId);
          })
          .catch(() => {});
      },
      getCity(districtId) {
        treeUtils.getDistrict({}).then(res => {
          const allData = res.districts;
          for (let i = 0; i < allData.length; i++) {
            // eslint-disable-next-line no-loop-func
            allData[i].subNodes.forEach(element => {
              if (element.id === districtId) {
                this.districtName = `${allData[i].name} ${element.name}`;
                console.log(this.districtName);
              }
            });
          }
        });
      },
      /**
       * 获取详情数据
       */
      getDetail() {
        const { id } = this.$route.query;
        marketManage.memberDetails({ userId: id }).then(response => {
          console.log('getDetail response', response);
          Object.assign(this.detail, response);
        });
      },
      getLevelInfo() {
        const { id } = this.$route.query;
        marketManage.findLevelInfoByUserId({ userId: id }).then(response => {
          console.log('findLevelInfoByUserId', response);
          this.vipLevelInfo = response;
        });
      },
      // toVip() {
      //   this.type = this.$route.query.type;
      //   const pathUrl = '/operationCenter/vipManage/vipManage/details?id=220695';
      //   // if (this.type === 'customerCharge') {
      //   //   pathUrl = '/customerCenter/customerCharge/customerDriver/customerDriverList';
      //   // } else if (this.type === 'customerInternal') {
      //   //   pathUrl = '/customerCenter/internalDriver/subsidiaryDriver/subsidiaryDriverList';
      //   // } else if (this.type === 'customerCustomer') {
      //   //   pathUrl = '/customerCenter/customerCustomer/customerCustomerList';
      //   // }
      //   this.$router.push({
      //     path: pathUrl,
      //     query: {
      //       id: this.id,
      //       userAccount: this.baseDetail.userAccount,
      //     },
      //   });
      // },
      getCardCouponInfo() {
        const { id } = this.$route.query;
        Promise.all([
          marketManage.queryCardCouponByUserId({
            userId: id,
            // 抵用卡
            cardCouponType: '1',
            // 使用状态（0:未使用，1:使用中，2:已使用，3:已过期，4:已作废），默认是 ['0', '1']
            useStatus: ['0', '1'],
          }),
          marketManage.queryCardCouponByUserId({
            userId: id,
            // 优惠券
            cardCouponType: '2',
            // 使用状态（0:未使用，1:使用中，2:已使用，3:已过期，4:已作废），默认是 ['0', '1']
            useStatus: ['0', '1'],
          }),
        ]).then(([cards, coupons]) => {
          console.log('queryCardCouponByUserId', cards, coupons);
          this.cardList = cards.cardCouponDetailList;
          this.couponList = coupons.cardCouponDetailList;
        });
      },
      handleAccountMoneyDetail() {
        this.type = this.$route.query.type;
        let pathUrl = '';
        if (this.type === 'customerCharge') {
          pathUrl = '/financialManagement/customerAccount/customDriverAccountsDetail';
        } else if (this.type === 'customerInternal') {
          pathUrl = '/financialManagement/subsidiaryAccount/internalCustomerAccountsDetail';
        } else if (this.type === 'customerCustomer') {
          pathUrl = '/financialManagement/accountManagement/cSideDriverAccountsDetail';
        }
        this.$router.push({
          path: pathUrl,
          query: {
            id: this.id,
          },
        });
      },
      // 获取对应角色的对应字段
      getCode(type) {
        let permissionCode = '';
        if (type === 'edit') {
          if (this.type === 'customerCharge') {
            permissionCode = 'customer:driver:edit';
          } else if (this.type === 'customerCustomer') {
            permissionCode = 'customer:customer:detail:edit';
          } else if (this.type === 'customerInternal') {
            permissionCode = 'internal:driver:detail:edit';
          }
        } else if (type === 'pwd') {
          if (this.type === 'customerCharge') {
            permissionCode = 'customer:driver:detail:pwd';
          } else if (this.type === 'customerCustomer') {
            permissionCode = 'customer:customer:detail:pwd';
          } else if (this.type === 'customerInternal') {
            permissionCode = 'internal:driver:detail:pwd';
          }
        } else if (type === 'balance') {
          if (this.type === 'customerCharge') {
            permissionCode = 'customer:driver:detail:balance';
          } else if (this.type === 'customerCustomer') {
            permissionCode = 'customer:customer:detail:balance';
          } else if (this.type === 'customerInternal') {
            permissionCode = 'internal:driver:detail:balance';
          }
        } else if (type === 'coupon') {
          if (this.type === 'customerCharge') {
            permissionCode = 'customer:driver:detail:coupon';
          } else if (this.type === 'customerCustomer') {
            permissionCode = 'customer:customer:detail:coupon';
          } else if (this.type === 'customerInternal') {
            permissionCode = 'internal:driver:detail:coupon';
          }
        } else if (type === 'card') {
          if (this.type === 'customerCharge') {
            permissionCode = 'customer:driver:detail:card';
          } else if (this.type === 'customerCustomer') {
            permissionCode = 'customer:customer:detail:card';
          } else if (this.type === 'customerInternal') {
            permissionCode = 'internal:driver:detail:card';
          }
        }
        return permissionCode;
      },
      // handleIntegralDetail() {
      //   const { id } = this.$route.query;
      //   this.goUrl(
      //     `/operationCenter/vipManage/vipManage/integralFlow?id=${id}&userAccount=${this.detail.memberAccount}`
      //   );
      // },
      // handleGrowthValueDetail() {
      //   const { id } = this.$route.query;
      //   this.goUrl(
      //     `/operationCenter/vipManage/vipManage/growthValueFlow?id=${id}&userAccount=${this.detail.memberAccount}`
      //   );
      // },
      // openAdjustIntegralDialog() {
      //   openAdjustDialog(
      //     'integral',
      //     {
      //       growthValue: this.detail.growthValue,
      //       points: this.detail.points,
      //       userAccount: this.detail.memberAccount,
      //     },
      //     () => {
      //       this.getDetail();
      //       this.getLevelInfo();
      //     }
      //   );
      // },
      handleEdit() {
        if (this.type === 'customerCharge') {
          this.stepsIndex = 0;
          this.maxStepIndex = 1;
          this.isEdit = true;
          this.drawerType = 'edit';
          this.showDrawer = true;
        } else if (this.type === 'customerInternal') {
          this.stepsIndex = 0;
          this.isEdit = true;
          this.drawerType = 'edit';
          this.showDrawer = true;
        } else if (this.type === 'customerCustomer') {
          this.stepsIndex = 0;
          this.showAddDrawer = true;
          this.isEdit = true;
          this.drawerType = 'edit';
          this.showDrawer = true;
        }
      },
      // openAdjustGrowthValueDialog() {
      //   openAdjustDialog(
      //     'growthValue',
      //     {
      //       growthValue: this.detail.growthValue,
      //       points: this.detail.points,
      //       userAccount: this.detail.memberAccount,
      //     },
      //     () => {
      //       this.getDetail();
      //       this.getLevelInfo();
      //     }
      //   );
      // },
      JumpToCarPage() {
        this.type = this.$route.query.type;
        let pathUrl = '';
        if (this.type === 'customerCharge') {
          pathUrl = '/customerCenter/customerCharge/customerVehicle/customerVehicleList';
        } else if (this.type === 'customerInternal') {
          pathUrl = '/customerCenter/internalDriver/subsidiaryVehicle/subsidiaryVehicleList';
        }
        this.$router.push({
          path: pathUrl,
          query: {
            id: this.id,
            userAccount: this.detail.userAccount,
          },
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .fleet-match-content {
    padding: 0 10px 10px;
    display: flex;
    flex-flow: column;

    .box-card-prop-right {
      width: 69.5%;
      height: 350px;
    }
    .box-card-prop-left {
      width: 30%;
      height: 350px;
      .customerProperty {
        .item-left {
          margin-top: 20px;
        }
        .label {
          color: #9b9b9b;
          font-size: 12px;
          margin-right: 10px;
        }
        .value {
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .box-card {
      // width: 50%;
      margin: 5px 0;
      .baseInfo {
        display: flex;
        font-size: 14px;
        .header {
          width: 160px;
          display: flex;
          justify-content: center;
          .head {
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            img {
              width: 80px;
              height: 80px;
              border-radius: 80px;
            }
            .level {
              position: relative;
              height: 30px;
              width: 90px;
              border-radius: 10px;
              bottom: 25px;
              color: #fff;
              text-align: right;
              padding-right: 6px;
              line-height: 17px;
              font-weight: 700;
              font-style: italic;
              font-size: 13px;
              background-size: 100%;
              padding: 8px 8px 0 10px;
              box-sizing: border-box;
              left: -7px;
              background-repeat: no-repeat;
              &.level1 {
                background-image: url('~@/assets/images/vip1.png');
              }
              &.level2 {
                background-image: url('~@/assets/images/vip2.png');
              }
              &.level3 {
                background-image: url('~@/assets/images/vip3.png');
              }
              &.level4 {
                background-image: url('~@/assets/images/vip4.png');
              }
              &.level5 {
                background-image: url('~@/assets/images/vip5.png');
              }
              // &::
            }
          }
        }
        .content {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .label {
            color: #9b9b9b;
            font-size: 12px;
            margin-right: 10px;
          }
          .value {
            font-weight: 400;
            font-size: 12px;
          }
          .top {
            display: flex;
            flex-wrap: wrap;
            & > div {
              width: calc(100% / 3);
              height: 30px;
              line-height: 30px;
              display: flex;
              .label {
                color: #9b9b9b;
                font-size: 12px;
                margin-right: 10px;
              }
              .value {
                font-weight: 400;
                font-size: 12px;
              }
            }
          }
        }
      }
      .vipInfo {
        display: flex;
        .item {
          border: 1px solid #3773fc80;
          background-color: #3773fc1a;
          .tRight {
            background-color: #3773fc24;
            color: #4d8cfd;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }
      .userInfo {
        display: flex;
        .item {
          border: 1px solid #44d7b680;
          background-color: #44d7b61a;
          .tRight {
            background-color: #44d7b624;
            color: #02c498;
            & > :last-child {
              margin-left: 4px;
            }
          }
        }
      }

      .item {
        position: relative;
        width: calc((100% - 20px) / 3);
        border-radius: 10px;
        height: 188px;
        margin-right: 20px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        &:last-child {
          margin-right: 0px;
        }
        .title {
          position: absolute;
          width: 100%;
          font-size: 14px;
          font-weight: bold;
          top: 0;
          left: 0;
          padding: 20px;
          display: flex;
          justify-content: space-between;
          box-sizing: border-box;
          .tRight {
            width: 76px;
            height: 32px;
            display: flex;
            justify-content: center;
            border-radius: 12px;
            align-items: center;
            cursor: pointer;
          }
        }
        .value {
          font-weight: 600;
          font-size: 40px;
          height: 56px;
          line-height: 56px;
        }
        .link {
          color: #4d8cfd;
          font-size: 12px;
          height: 17px;
          line-height: 17px;
          cursor: pointer;
        }
      }
      .itembg {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 72px;
        height: 72px;
        background-repeat: no-repeat;
        background-size: 100%;
        &.bg1 {
          background-image: url(../../../../assets/images/itembg1.png);
        }
        &.bg2 {
          background-image: url(../../../../assets/images/itembg2.png);
        }
        &.bg3 {
          background-image: url(../../../../assets/images/itembg3.png);
        }
        &.bg4 {
          background-image: url(../../../../assets/images/itembg4.png);
        }
        &.bg5 {
          background-image: url(../../../../assets/images/itembg5.png);
        }
        &.bg6 {
          background-image: url(~@/assets/images/itembg6.png);
        }
      }
      .ellipsis {
        width: 500px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      table {
        width: 100%;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        th {
          height: 44px;
        }
        td {
          height: 40px;
        }
        th,
        td {
          border-bottom: 1px solid #ccc;
          border-right: 1px solid #ccc;
        }
      }
    }
  }
  ::v-deep .levelInfo {
    // height: 500px;
    .card {
      height: 160px;
      border-radius: 10px;
      background-repeat: no-repeat;
      background-size: 100%;
      padding: 30px;
      box-sizing: border-box;
      .title {
        font-size: 26px;
        font-family: YouSheBiaoTiHei;
        font-weight: bold;
        font-style: italic;
        // -webkit-background-clip: text;
        color: transparent;
        margin-bottom: 28px;
        display: inline-block;
      }
      .text {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-bottom: 9px;
        span {
          font-weight: bold;
          font-size: 20px;
          margin-right: 2px;
        }
      }
      .progress {
        background: rgba(255, 255, 255, 0.4);
        border-radius: 40px;
        height: 6px;
        .valueLine {
          width: 0px;
          height: 100%;
          border-radius: 40px;
        }
      }
      &.vip1 {
        background-image: url('~@/assets/images/vipbg1.png');
        .title {
          color: #1779fe;
          // background-image: linear-gradient(90deg, #1779fe 0%, #8dbdff 100%);
        }
        .text {
          color: #1779fe;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #9fc8ff 0%, #1779fe 100%);
        }
      }
      &.vip2 {
        background-image: url('~@/assets/images/vipbg2.png');
        .title {
          color: #415367;
          // background-image: linear-gradient(90deg, #415367 0%, #9fb4cc 100%);
        }
        .text {
          color: #415367;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #b6cee9 0%, #415367 100%);
        }
      }
      &.vip3 {
        background-image: url('~@/assets/images/vipbg3.png');
        .title {
          color: #b75500;
          // background-image: linear-gradient(90deg, #b75500 0%, #ff9c03 100%);
        }
        .text {
          color: #b75500;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #ff9c03 0%, #b75500 100%);
        }
      }
      &.vip4 {
        background-image: url('~@/assets/images/vipbg4.png');
        .title {
          color: #3623cf;
          // background-image: linear-gradient(90deg, #3623cf 0%, #947cff 100%);
        }
        .text {
          color: #3623cf;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #947cff 0%, #3623cf 100%);
        }
      }
      &.vip5 {
        background-image: url('~@/assets/images/vipbg5.png');
        .title {
          color: #f7de9c;
          // background-image: linear-gradient(90deg, #f7ca72 0%, #f7de9c 100%);
        }
        .text {
          color: #f7de9c;
        }
        .progress .valueLine {
          background: linear-gradient(90deg, #f7de9b 0%, #f7ca72 100%);
        }
      }
    }
    .quanyi {
      margin-top: 20px;
      border-radius: 30px;
      border: 1px solid #ebebf0;
      padding: 20px 30px;
      & > * {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
        &.title {
          font-size: 14px;
          font-weight: bold;
        }
        &._item {
          display: flex;
          img {
            width: 40px;
            height: 40px;
            margin-right: 20px;
          }
          & > div {
            flex-grow: 1;
            font-size: 12px;
            font-weight: 400;
            .name {
              color: #9b9b9b;
              margin-bottom: 6px;
            }
            .text {
              line-height: 17px;
            }
          }
        }
      }
    }
  }
  ::v-deep .coupon {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('~@/assets/images/coupon.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
          &.card-expire-time {
            display: flex;
            align-items: center;
            .expire-tag {
              margin-left: 8px;
              padding: 0px 6px;
              font-size: 10px;
              color: #e02020;
              border-radius: 8px;
              border: 1px solid #e02020;
            }
          }
        }
      }
    }
  }
  ::v-deep .card {
    ._item {
      display: flex;
      .left {
        width: 73px;
        height: 75px;
        background: url('~@/assets/images/card.png') no-repeat;
        background-size: 100%;
        .money {
          color: #90552f;
          font-size: 12px;
          margin-top: 6px;
          text-align: center;
          height: 23px;
          span {
            font-family: AlibabaPuHuiTi_2_85_Bold;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .param {
          color: #f9f9f9;
          font-size: 10px;
          margin-top: 10px;
          padding: 0 2px;
          display: flex;
          text-align: center;
          justify-content: center;
          align-items: center;
        }
      }
      .right {
        flex-grow: 1;
        margin-left: 14px;
        display: flex;
        flex-flow: column;
        justify-content: center;
        .title {
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 8px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
        .info {
          margin-bottom: 5px;
          color: #9b9b9b;
          font-size: 12px;
          font-weight: 400;
        }
      }
    }
  }
  .more-car {
    position: absolute;
    right: 0;
    top: -30px;
    border-width: 1px;
    border-color: #4d8cfd;
    border-style: solid;
    color: #4d8cfd;
    padding: 4px 6px;
    font-size: 12px;
    cursor: pointer;
  }
  .more-vip {
    margin-left: 8px;
    font-size: 12px;
    color: #00f;
    cursor: pointer;
  }
</style>
